import Button from "../components/Button";
import Container from "../components/Container";
import {generateTitle} from "../helpers/seo";
import Head from "next/head";

export default function Custom404() {
    return (
        <section className="w-full min-h-screen flex flex-col">
            <Head>
                <title>{generateTitle('404 - Page Not Found')}</title>
            </Head>
            <Container className="m-auto flex flex-col">
                <header className="flex flex-col">
                    <h1 className="m-auto text-5xl lg:text-7xl font-semibold flex flex-col items-center text-center">
                        <span className="text-gradient">Page not found.</span>
                    </h1>
                </header>
                <Button href="/" className="m-auto mt-8">Go to home page</Button>
            </Container>
        </section>
    )
}